<template>
  <div>
    <v-dialog v-model="showQr" max-width="400px">
      <v-card>
        <v-card-title class="headline">&nbsp;</v-card-title>
        <v-card-text>
          <div id="download-qr" v-if="showQr">
            <img :src="qrCode" alt="QR code" />
            <div v-html="qrText"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="showQr = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <contact v-if="page.component === 'contact'" :page="page"></contact>
    <request v-if="page.component === 'request'" :page="page"></request>
    <div class="page-container">
      <!-- <div class="zoom-note" v-if="page.zoomNote" v-html="page.zoomNote">
      </div> -->
      <v-img
        v-if="page.zoomNote"
        :src="require('@/assets/pinch-note.svg')"
        class="pinch-note"
        contain
      />
      <div
        class="disclaimer"
        v-if="page.disclaimer"
        v-html="page.disclaimer"
      ></div>

      <div v-if="page.contentImage">
        <v-img :src="`images/${page.contentImage}`"></v-img>
      </div>
      <div class="reference" v-if="page.text" v-html="page.text"></div>
      <block-panel-content
        v-if="page.block"
        :class="page.block.class"
        :block="page.block"
        :page="page"
      ></block-panel-content>
      <v-expansion-panels
        v-if="page.blocks"
        focusable
        @change="changed"
        v-model="panels"
        elevation="0"
      >
        <v-expansion-panel v-for="(block, i) in page.blocks" :key="i">
          <v-expansion-panel-header
            class="mt-1 mb-0 text-subtitle-1 font-weight-bold"
            :style="`color: ${page.color}`"
            @click="toggle(block)"
            disable-icon-rotate
          >
            <span v-html="block.title"></span>
            <template v-slot:actions>
              <v-icon size="32" color="#eb1600">
                {{
                  panels === i
                    ? "mdi-arrow-down-circle"
                    : "mdi-arrow-right-circle"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <block-panel-content
              :block="block"
              :page="page"
            ></block-panel-content>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="disclaimer2 disclaimer3">
      Your use of information on this site is subject to the terms of our
      <a
        href="#"
        @click.prevent="
          extLink(
            'https://www.janssen.com/legal-notice',
            'legal.png',
            'Use your cell phone to scan this QR code to view Johnson & Johnson\'s legal notice.'
          )
        "
        >Legal Notice</a
      >. Please see our
      <a
        href="#"
        @click.prevent="
          extLink(
            'https://www.janssen.com/privacy-policy',
            'privacy.png',
            'Use your cell phone to scan this QR code to view Johnson & Johnson\'s privacy policy.'
          )
        "
        >Privacy Policy</a
      >. This site is published by Janssen Global Services, LLC, which is solely
      responsible for&nbsp;its&nbsp;contents.
      <br />
      &copy; Janssen Global Services, LLC. 2024 09/24 cp-320493v8
      <v-spacer></v-spacer>
      <div>
        <v-btn
          text
          dark
          href="#"
          @click.prevent="
            extLink(
              'https://www.linkedin.com/company/jnjinnovativemedicine/',
              'linkedin.png',
              'Use your cell phone to scan this QR code to view Johnson & Johnson\'s LinkedIn profile'
            )
          "
        >
          <v-icon color="black">mdi-linkedin</v-icon>
        </v-btn>
        <v-btn
          text
          dark
          href="#"
          @click.prevent="
            extLink(
              'https://www.linkedin.com/company/jnjinnovativemedicine/',
              'twitter.png',
              'Use your cell phone to scan this QR code to view Johnson & Johnson\'s X account'
            )
          "
        >
          <img
            src="images/twitter.svg"
            style="height: 24px; filter: invert(100%);"
          />
        </v-btn>
        <v-btn
          text
          dark
          href="#"
          @click.prevent="
            extLink(
              'https://www.instagram.com/jnjinnovativemedicine/',
              'instagram.png',
              'Use your cell phone to scan this QR code to view Johnson & Johnson\'s Instagram account'
            )
          "
        >
          <v-icon color="black">mdi-instagram</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import analytics from "../services/analytics";
export default {
  name: "Page",
  components: {
    Request: () => import("@/components/Request.vue"),
    Contact: () => import("@/components/Contact.vue"),
    BlockPanelContent: () => import("@/components/blocks/BlockPanelContent.vue")
  },
  props: ["pages", "page"],
  data: () => ({
    isMobile: false,
    panels: undefined,
    qrMode: false,
    showQr: false,
    qrCode: "",
    qrText: ""
  }),
  methods: {
    changed() {
      this.$hub.$emit("startScreenSaverCheck");
      console.log(this.panels);
    },
    toggle(block) {
      analytics.tab(block.title);
      if (block.type === "pdf" && this.isMobile) {
        this.$hub.$emit("showPdfPopup", block);
        this.tab = -1;
        return false;
      }
      this.$hub.$emit("pauseAllMedia", block);
    },
    greet: function(event) {
      alert("Hello " + this.name + "!");
    },
    setWidth() {
      this.isMobile = window.innerWidth < 1024;
    },
    openBlock(block) {
      block.page = this.page;
      if (block.type === "link") {
        window.open(block.url, "_blank");
      } else {
        this.$hub.$emit("openBlock", block);
      }
    },
    extLink(url, img, text, alwaysNewTab = false) {
      console.log("extLink", url, img);
      this.qrCode = "/images/qr/" + img;
      this.qrText = text;
      this.showQr = true;
    }
  },
  created() {
    window.addEventListener("resize", this.setWidth);
  },
  mounted() {
    window.$app.playing = false;
    window.$app.checking = true;
    this.setWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  }
};
</script>

<style lang="scss">
.v-application .v-expansion-panel .text-subtitle-1 {
  line-height: 120% !important;
}
.page-header {
  padding: 24px;
  color: white;
  .menu-link-icon {
    float: left;
    margin-right: 10px;
  }
  .page-header-text {
    font-size: 2em;
    line-height: 48px;
  }
  .page-header-text-mobile {
    font-size: 1.33em;
    line-height: 115%;
    padding: 12px 0;
  }
}
.page-container {
  max-width: 1500px;
  margin: 16px auto;
  .reference {
    font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
    line-height: 110%;
    font-weight: 100;
    font-size: 14px;
  }
  .block-btn {
    min-height: 64px !important;
    font-size: 24px;
    width: calc(100% - 32px);
    margin: 16px 16px 0;
    .v-btn__content {
      white-space: normal !important;
      width: 100% !important;
      padding: 10px 0;
    }
  }
  .block-btn-mobile {
    font-size: 16px;
    min-height: 48px !important;
  }
}
.video-block {
  max-width: 125vh !important;
  min-width: 100% !important;
  border: 1px solid black;
  padding: 0 !important;
  margin-bottom: 1rem;
}
.pdf-block {
  max-width: 125vh !important;
  min-width: 100% !important;
  // border: 1px solid black;
  padding: 0 !important;
  margin-bottom: 20px;
}
.pinch-note {
  display: inline-block;
  position: relative;
  margin: 0 auto 5px 0;
  max-height: 50px;
  align-self: left;
  .v-image__image {
    // img {
    background-position: left center !important;
  }
}
.disclaimer {
  font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
  color: #000;
  line-height: 110%;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel {
  background-color: initial !important;
  border-bottom: 1px solid black;
}
.v-expansion-panel-header {
  span {
    font-weight: normal;
    color: black;
  }
  &::before {
    display: none;
  }
}
.v-expansion-panels {
  border-radius: 0 !important;
  border-top: 1px solid black;
}
// .v-expansion-panel-content {}
@media only screen and (max-width: 420px) {
  .v-expansion-panel {
    .v-btn {
      height: unset !important;
      padding: 10px 16px !important;
      width: 100% !important;
      span {
        white-space: normal;
        flex: 1 1 auto;
        align-items: start;
      }
    }
  }
}
.disclaimer3 {
  position: absolute;
  bottom: 60px;
  width: 70%;
  left: 15%;
}
@media screen and (max-width: 450px) {
  .disclaimer3 {
    font-size: 9px !important;
    width: 100% !important;
    left: 0 !important;
    // bottom: 100px;
  }
}
</style>
